import React from 'react';
import {Blur,blurAnimation,titleAnimation} from '../../utils/cssEffects';
import './css/offer.css';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {TiMail, TiPhone} from 'react-icons/ti';
import {MdLocationOn} from 'react-icons/md';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import offerImage from './static/offerimage.jpg';
import CardColumns from 'react-bootstrap/CardColumns';
import factory from './static/factory.png';
import oil from './static/oil.svg';
import hospital from './static/hospital.png';
import enviromentalsvg from './static/environmentalsvg.png';
import powerplant from './static/power_plant.png';
import car from './static/car.png';
import energetic from './static/energetic.png';
import agro from './static/agro.png';
import building from './static/industry-building.png';
import {Background} from '../../utils/stylingHelper.js';
import nozzle from './static/nozzle.jpg';
import part1 from './static/part1.jpg';
import part2 from './static/part2.jpg';
import part3 from './static/part3.jpg';
import part4 from './static/part4.jpg';
import part5 from './static/part5.jpg';
import sl300 from './static/s-l300.jpg';
import turbine from './static/turbine.jpg';
import turbine_blade from './static/turbine_blade.jpg';
import turbine_blades from './static/turbine_blades.jpg';



const BlockSpace = styled.div`
padding-top:10vh;
margin:20px;
`
const ArticleHeader=styled.div`
  text-align: center;
  padding-top:5vh;
  font-size:4rem;
  letter-spacing:4px;
  animation: ${titleAnimation} 1s ease-out;

`

const ArticleBody=styled.div`
padding-top:10vh;
`

const CardBlock = styled.div`
border-right:1px solid #ccc;
margin:20px;
text-align:center;
valign:middle;
overflow:hidden;
padding:50px;
`

const CardImage= styled.div`
text-align:center;
font-weight:bold;
width:100px;
height:100px;
margin:auto;
`
const CardBody = styled.div`
text-align:center;
font-size:1.2rem;
margin-top:20px;
width:100%;
font-weight:bold
letter-spacing:4px;
`

function Product()
{
  return(

  <Blur>
    <Background bg="linear-gradient(0deg, rgba(224,222,201,1) 0%, rgba(229,193,124,1) 100%);">
  <Row>
    <Col md={12}>
        <div className="firma-tytul">
          <h1>Our Product</h1>
          <div className="firma-nav">
          <p><a href="/"><b>PBP Europe</b> > Our Product</a></p>
          </div>
        </div>
    </Col>
  </Row>
    <Row>
      <Col md={12}>
      <ArticleHeader>
          Our Product
      </ArticleHeader>
       </Col>
    </Row>
    <Row>
        <Col md={6} sm={12}>
            <Image src={offerImage} className="image"/>
        </Col>
        <Col md={6} sm={12}>
              <ArticleBody>
                <div className="blok-content">
                PBP carries out activities for the benefit of entities operating among other things in the following industry sectors: oil and gas industry, medical, automotive, construction, rail, energy, agriculture
                </div>
                <div className="blok-content">
                We are proud to be able to offer products and services at the best market price and the highest quality available, using the engineering and manufacturing capabilities of Poland country and its other European partners.
                </div>
              </ArticleBody>
         </Col>
     </Row>
     
    <Row>
      <Col md={12}>
        <ArticleHeader>
          Industrial sectors:
        </ArticleHeader>
      </Col>
    </Row>
    <Row>
      <div className="dummy"></div>
    </Row>
    <Row>
      <Col md={6} sm={12}>
        <CardBlock>
            <CardImage>
              <Image src={oil} fluid/>
            </CardImage>
            <CardBody>
              Oil and gas
            </CardBody>
        </CardBlock>
      </Col>
      <Col md={6} sm={12}>
        <CardBlock >
            <CardImage>
              <Image src={car} fluid/>
            </CardImage>
            <CardBody>
              Automotive
            </CardBody>
        </CardBlock>
      </Col>
        <Col md={6} sm={12}>
          <CardBlock>
              <CardImage>
                <Image src={building} fluid/>
              </CardImage>
              <CardBody>
                Construction
              </CardBody>
          </CardBlock>
        </Col>
        <Col md={6} sm={12}>
          <CardBlock>
              <CardImage>
                <Image src={energetic} fluid/>
              </CardImage>
              <CardBody>
              Rail and energy
              </CardBody>
          </CardBlock>
        </Col>
    </Row>
    <div className="offerWrapper">
        <Row>
        <Col md={12} className="text-center">
          <h1>We have ability and capability to manufacture and supply:</h1>
          </Col>
         </Row>
         <Row>
           <Col md={6} sm={12} className="spaceTop">
              <Col md={12} className="text-center"><h2>Gas Turbine and compressor Parts :</h2></Col>
              <Col md={12}>
              <ul>
                  <li>Vane</li>
                  <li>Blade</li>
                  <li>Shroud</li>
                  <li>Nozzle</li>
                  <li>Rotor & Disk</li>
                  <li>Bearing</li>
                  <li>Segment</li>
                  <li>TP & Liner</li>
                </ul>
              </Col>
           </Col>
           <Col md={6} sm={12}>
              <Image src={part1} width={200} height={200} rounded />
              <Image src={part2} width={200} height={200} rounded />
              <Image src={part3} width={200} height={200} rounded />
              <Image src={part4} width={200} height={200} rounded />
              <Image src={part5} width={200} height={200} rounded />
              <Image src={sl300} width={200} height={200} rounded />
           </Col>
         </Row>
         <Row>
          <Col md={6} sm={12} className="spaceTop">
              <Image src={turbine} width={200} height={200} rounded />
              <Image src={turbine_blade} width={200} height={200} rounded />
              <Image src={turbine_blades} width={200} height={200} rounded />
              <Image src={nozzle} width={200} height={200} rounded />
          </Col>
         <Col md={6} sm={12} className="spaceTop">
              <Col md={12} className="text-center"><h2>Steam turbine Parts :</h2></Col>
              <Col md={12}>
                <ul>
                  <li>Blades</li>
                  <li>Vanes</li>
                  <li>Rotor</li>
                  <li>Diaphragms</li>
                  <li>Shroud</li>
                  <li>Impeller</li>
                  <li>Bearing</li>
                </ul>
              </Col>
           </Col>
         </Row>
         <Row>
           <Col md={12} className="text-center spaceTop">
          <h2>We know very well all processes related to manufacturing including :</h2> 
           </Col>
           <Col md={6}>
             <ul>
               <li>Casting</li>
               <li>Forging</li>
               <li>Welding & Brazing</li>
             </ul>
             </Col>
             <Col md={6}>
               <ul>
              <li>Machining</li>
               <li>Forming</li>
               <li>Coating</li>
               </ul>
             </Col>
         </Row>
    </div>
    </Background>
    </Blur>

  )
}

export default Product;

import React,{useState} from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import styled from 'styled-components';
import './css/slider.css';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './css/main.css';
import Image from 'react-bootstrap/Image';
import {Blur,blurAnimation} from '../../utils/cssEffects';
import slide1 from './static/slide1.jpg';
import slide2 from './static/slide2.jpg';
import slide3 from './static/slide3.jpg';
import {Background} from '../../utils/stylingHelper.js';

const Header = styled.div`
text-align:center;
font-size:3rem;
font-family: 'Roboto Mono', monospace;
padding:20vh 0 20vh 0;
`

const Card = styled.div`
padding:2%;
`

const StyledHr = styled.hr`
border: 0;
border-bottom: 2px solid #ccc;
background: #999;
width:60%;
margin-top:20vh;
margin-bottom:20vh;
`


const content = [
    {
    title: "PBP",
    description:
      "PBP Europe Sp. z o.o",
    image: slide1,
  },

  {
  title: "PBP Europe",
  description:
    "Professionalism Trust Potential",
  image: slide2,
  },
  {
  title: "Worldwide Range ",
  description:
    "We care the world.",
  image: slide3,
  },

]

function Main()
{

  return(
    <Background bg="#25a9f0">
      <Blur>
    <Slider className="slider" autoplay="3000">
      {content.map((article, index) => <div
        style={{background: `url('${article.image}')`}}
        key={index}>
    <h2 className="title">{article.title}</h2>
    <div className="description">{article.description}</div>
  </div>)}
    </Slider>
    </Blur>
    </Background>


);
}

export default Main;

import React from 'react';
import styled,{keyframes} from 'styled-components';

const titleAnimation = keyframes`
0% { width:150px; }
100% { width:100%; }
`

const blurAnimation = keyframes`
0%{
  filter:blur(300px);
}

100%{
  filter:blur(0px);
}
`
const Blur= styled.div`
animation: ${blurAnimation} .5s both;
`

export {
blurAnimation,
Blur,
titleAnimation
};

import React,{useState,useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Info from './parts/info/Info';
import Menu from './parts/menu/Menu';
import LoadBootstrap from './parts/utils/LoadBootstrap';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Footer from './parts/footer/Footer';
import styled,{keyframes} from 'styled-components';

const fadein = keyframes`
0%{
  opacity:0;
}

100%{
  opacity:1;
}
`
const Loader = styled.div`
position:fixed;
width:100%;
height:100vh;
background-color:#fff;
z-index:9999;
display:${(props)=>props.show?'block':'none'};
margin:0 !important;
`

const LoaderBlock = styled.div`
text-align:center;
`

const FadeIn= styled.div`
animation: ${fadein} 3s both;
`

function App() {

  const [displayDocument,setDisplayDocument]= useState(false);
  const [displayLoader,setDisplayLoader] = useState(true);

  useEffect(()=>{
    setDisplayDocument(true);
      setDisplayLoader(false);
  })

  return (
    <div>
    <meta name="authors" content="Kamil Wołoszyn, Mikołaj Wołoszyn" />
    <Loader show={displayLoader}>
          <LoaderBlock >
              <img className="loader-element" src={logo} />
          </LoaderBlock>
    </Loader>
    <FadeIn>
      <Container fluid={true} style={{display:`${(displayDocument)?'block':'none'}`}}>
        <LoadBootstrap />
            <Row>
                <Info />
            </Row>
            <Row>
                <Menu />
            </Row>
                <Footer />

      </Container>
    </FadeIn>
    </div>

  );
}

export default App;

import React from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './css/info.css';
import {TiMail, TiPhone} from 'react-icons/ti';
import {MdLocationOn} from 'react-icons/md';
import detectBrowserLanguage from 'detect-browser-language';


function Info()
{
  return(
  <Col md={12}>
  <div className="info">
    <Row>
       <Col md={6}>
          <TiMail /><a href='mailto:kontakt@pbpeurope.com'>kontakt@pbpeurope.com</a>
       </Col>
       <Col md={6}>
         <span>
            <TiPhone /> +48 667 165 055
         </span>
       </Col>

    </Row>
  </div>
</Col>
  );
}

export default Info;

import React,{useState,useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {isValidPhoneNumber} from 'react-phone-number-input';
import * as emailjs from 'emailjs-com';
import {Blur,blurAnimation} from '../../utils/cssEffects';
import './css/contact.css';
import {Background} from '../../utils/stylingHelper.js'
import logo from './static/logo.svg';
import Image from 'react-bootstrap/Image';

const FormTitle =  styled.div`
text-align:center;
font-weight:bold;
font-size: 1.5rem;
padding: 100px 0 50px 0 ;
`
const Title = styled.div`
text-align:center;
font-size:3rem;
padding:5vh 0 5vh 0;
letter-spacing:3px;
font-weight:400;
`

const ContactBlock = styled.div`
padding-bottom:30px;
`
function useCheckIfEmailCorrect(email)
{
  if(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

}

function useCheckIfPhoneNumberCorrect(number)
{
  if(number)
      return isValidPhoneNumber(number) ? true : false;
}

function useCheckIfMessageCorrect(mess)
{
  if(mess)
  {
    if(mess.length>10)
      return true;
    return false;
  }

}


function Contact()
{

const [emailValue,setEmailValue]= useState();
const [numberValue,setNumberValue] = useState();
const [message,setMessage] = useState();


const validationEmailResponse = useCheckIfEmailCorrect(emailValue);
const validationNumberResponse = useCheckIfPhoneNumberCorrect(numberValue);
const validationMessageResponse = useCheckIfMessageCorrect(message);


  return(
  <Background bg="#25a9f0">
    <Blur>
      <ContactBlock>
      <Row>
          <Col md={12}>
        <div className="firma-tytul">
          <h1>Kontakt</h1>
          <div className="firma-nav">
          <p><a href="/"><b>PBPeurope</b></a> > Contact </p>
          </div>
        </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Title>
            Contact
          </Title>
        </Col>

      </Row>

      <Row>
        <Col md={6} sm={12} style={{textAlign:"center"}} >
          <div className="logo"><Image src={logo} fluid/></div>
        </Col>

        <Col md={6} sm={12}>
            <Row>
              <Col md={12}>
              <FormTitle>
                Contact form
                </FormTitle>
              </Col>
            </Row>
            <Row>
            <Col md={12}>
            <div className="formularz">
              <Form>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control isInvalid={(validationEmailResponse=== undefined)?null:!validationEmailResponse} isValid={validationEmailResponse} onChange={e=> setEmailValue(e.target.value) } type="email" placeholder="name@domain.com" />
                  </Form.Group>
                  <Form.Group controlId="phone" >
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control type="text" isInvalid={(validationNumberResponse === undefined)?null:!validationNumberResponse} isValid={validationNumberResponse} onChange={e=> setNumberValue(e.target.value)} placeholder="+00 000 000 000" />
                  </Form.Group>
                  <Form.Group controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" isInvalid={(validationMessageResponse ===undefined) ? null:!validationMessageResponse}  isValid={validationMessageResponse} onChange={e=> setMessage(e.target.value)} rows="3" />
                  </Form.Group>
                  <Button variant="outline-primary" size="sm" type="submit" block disabled={(validationEmailResponse&&validationNumberResponse&&validationMessageResponse)?false:true}>
                    Send
                  </Button>
                </Form>
                </div>
                </Col>
            </Row>

        </Col>
      </Row>


      </ContactBlock>
    </Blur>
  </Background>
  )
}

export default Contact;

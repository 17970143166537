import React from 'react';
import styled,{keyframes} from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './css/about.css';
import {TiMail, TiPhone} from 'react-icons/ti';
import {MdLocationOn} from 'react-icons/md';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import {Blur,blurAnimation} from '../../utils/cssEffects';
import image1 from './static/grafika.jpg';
import {Background} from '../../utils/stylingHelper.js'

const BlockSpace = styled.div`
padding-top:10vh;
`
const ArticleHeader=styled.div`
  text-align: center;
  padding:5vh 0 5vh 0;
`

const ArticleBody=styled.div`
padding-top:5vh;
`

function About()
{
  return(
<Background bg="#e5c17c">
  <Blur>
      <Row>
        <Col md={12}>
            <div className="firma-tytul">
                <h1>About us</h1>
              <div className="firma-nav">
              <p><a href="/"><b>PBP Europe</b></a> > About us </p>
              </div>
            </div>
        </Col>
      </Row>
        <Row>
          <Col md={12}>
          <ArticleHeader>
              <h1 className="about-header">PBP Europe Sp. z.o.o </h1>
          </ArticleHeader>
           </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
            <Image src={image1} className="t1" />

             </Col>
              <Col md={6} sm={12}>
                  <ArticleBody>
                    <div className="blok-content">
                    PBP Company established in Sosnowiec to follow up Service activities for projects related to different aspects of energy sector and power plants.
                    </div>
                    <div className="blok-content">The PBP Engineering and managers team combines years of steam and gas turbine expertise with manufacturing knowledge together these facets enable us to uniquely tackle any project that comes our way.</div>
                    <div className="blok-content">
                    Our engineering team have knowledge to do reverse engineering of each part so If you are having difficulty sourcing a part, contact PBP and we can reverse engineer a provided sample to fill your needs.
                    </div>
                    <div className="blok-content">Each new project is managed from design to delivery by a project engineer to maximize quality and minimize delivery time and manufacturing cost.</div>
                  </ArticleBody>
             </Col>
         </Row>
         <Row>
           <Col md={12}>
            <BlockSpace></BlockSpace>
           </Col>
         </Row>
  </Blur>
</Background>




  );
}

export default About;

import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import {BrowserRouter as Router,Route,Link} from "react-router-dom";
import Contact from "../sites/contact/Contact";
import Product from "../sites/product/Product";
import About from "../sites/about/About";
import Main from '../sites/main/Main';
import Col from 'react-bootstrap/Col';
import './css/responsive.css';
import './css/style.css';

const PrzyciskNapis = styled.div`
text-transform: uppercase;
transition: all 1s !important;
color:#000;
&:hover{
  color: #fff !important;
}
`



function Menu()
{
  return(

    <Col md={12}>
      <Router>
      <Navbar bg="light" expand="md" sticky="top">
    <Navbar.Brand href="/">PBPeurope</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
         <Link className="nav-link" role="button" to="/"> <PrzyciskNapis> Home </PrzyciskNapis> </Link>
         <Link className="nav-link" role="button" to="/about"><PrzyciskNapis>About us</PrzyciskNapis> </Link>
         <Link className="nav-link" role="button" to="/product"> <PrzyciskNapis> Our Product</PrzyciskNapis> </Link>
         <Link className="nav-link" role="button" to="/contact"> <PrzyciskNapis>Contact </PrzyciskNapis> </Link>
      </Nav>
    </Navbar.Collapse>

  </Navbar>
    <Route path="/" exact component={Main} />
    <Route path="/about/" component={About} />
    <Route path="/product/" component={Product} />
    <Route path="/contact/" component={Contact} />
    </Router>
    </Col>

  );
}

export default Menu;

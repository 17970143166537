import React from 'react';
import styled from 'styled-components';


const Background = styled.div`
background:${(props => props.bg?props.bg:'#FFF')};
`

export {
  Background
}

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './css/footer.css';
import styled from 'styled-components';
import Googleplus from './static/Googleplus.svg';
import Linkedin from './static/Linkedin.svg';
import {TiMail, TiPhone} from 'react-icons/ti';
import {MdLocationOn} from 'react-icons/md';
import {FaLinkedin, FaGooglePlusSquare} from 'react-icons/fa';



function Footer()
{
  return(
    <div>
  <div className="stopka">
    <Row>
      <Col md={12} lg={6}>
          <div className="card-footer">
            <h5>PBP Europe Sp. z.o.o</h5><br/>
            <p>Firma PBPE Spółka z Ograniczoną Odpowiedzialnością Nr.REGON:384016584 Kapitał zakładowy: 50 000 PLN (opłacony w całości) </p><br/>
          </div>
      </Col>
      <Col md={12} lg={6}>
          <div className="card-footer">
            <h5>Contact:</h5><br/>
            <Row>
            <Col>
                <ul className="element-listy">
                 <li><a href="mailto:kontakt@pbpeurope.com">kontakt@pbpeurope.com</a></li>
                </ul>
            </Col>
            <Col>
            <ul className="element-listy">
             <li><TiPhone/> +48 667 165 055</li>
             <li></li>
            </ul>
            </Col>
            </Row>
          </div>
      </Col>
    </Row>
  </div>
  <div>
    <footer className="stopka2">
        <p>Copyright © PBP Europe 2020</p>
    </footer>
  </div>
</div>



  )
}

export default Footer;
